import useUIStore from '@stores/ui'

export const useViewLimit = () => {
  const increaseViewCount = useUIStore(
    (state) => state.actions.increaseViewCount
  )
  const viewLimitReached = useUIStore((state) => state.viewLimitReached)
  const viewLimitDisabled = useUIStore((state) => state.viewLimitDisabled)

  return {
    increaseViewCount,
    viewLimitReached,
    viewLimitDisabled,
  }
}
