/** @jsx jsx */
import React from 'react'
import { jsx, Flex, Box } from 'theme-ui'

import bgGridImage from '@styles/images/bg-grid.svg'
import bgGridNegativeImage from '@styles/images/bg-grid-negative.svg'
import bgPlanet from '@styles/images//bg-planet.svg'
import { Modals } from '@components/Modals'
import { Metadata } from '@components/Metadata'
import NavBarProduct from '@ui/NavBarProduct'
import HUD from '@ui/HUD'
import { CookieNotice } from '@components/CookieNotice'
import { Spinner } from '@components/Spinner'
import NavBar from './ui/NavBar'
import { useRouter } from 'next/router'

const backgroundLookup = {
  grid: bgGridImage,
  gridNegative: bgGridNegativeImage,
  planet: bgPlanet,
}

export interface LayoutProps {
  product?: boolean
  children?: React.ReactNode
  grid?: boolean
  backgroundColor?: 'red' | 'blue' | 'black' | 'navy3d' | 'green' | 'pink'
  backgroundImage?: 'grid' | 'gridNegative' | 'planet'
  backButtonTextColor?: string
  noBackButton?: boolean
}

export const Layout: React.FC<LayoutProps> = ({
  product,
  children,
  backgroundImage,
  backgroundColor = 'blue',
  backButtonTextColor = 'primary',
  noBackButton,
}) => {
  const router = useRouter()

  const isDiscoverPage = router.pathname === '/ontdekken'
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
        backgroundImage:
          backgroundImage && `url(${backgroundLookup[backgroundImage]})`,
        backgroundPosition:
          backgroundImage === 'planet' ? 'center center' : 'left top',
        backgroundAttachment: backgroundImage === 'planet' ? 'fixed' : 'unset',
        backgroundSize: backgroundImage === 'planet' ? 'cover' : 'repeat',
        backgroundRepeat: 'repeat',
        bg: (theme) => `${(theme?.colors || {})[backgroundColor]}`,
        minHeight: '100vh',
      }}
    >
      <Metadata />
      {product ? (
        <NavBarProduct
          backButtonTextColor={backButtonTextColor}
          noBackButton={noBackButton}
        />
      ) : isDiscoverPage ? (
        <HUD />
      ) : (
        <NavBar />
      )}
      <Flex as="main" sx={{ flexDirection: 'column', flexGrow: 1 }}>
        {children}
      </Flex>
      <Modals />
      <CookieNotice />
    </Flex>
  )
}

export const LayoutIsLoading: React.FC<LayoutProps> = (props) => {
  return (
    <Layout {...props}>
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <Spinner />
      </Box>
    </Layout>
  )
}
