import fetch, { APIRequestInit } from '../fetch'

// Extracted from: https://sync-api.medialoc.eu/ui/playground
export const StreamErrorCodes = {
  '0000': 'Unknown error',
  '1001': 'Invalid user',
  '1002': 'Invalid film',
  '1003': 'Invalid show',
  '2001': 'valid membership, but no access outside the benelux',
  '2002': 'valid ticket, but no access outside the benelux',
  '2003': 'no access from current location',
  '2004': 'no access outside the benelux',
  '2005': 'no access outside the netherlands',
  '3001': 'show not open',
  '3002': 'maximum capacity reached',
  '3003': 'no access to the show',
  '3004': 'the same account is already active',
}

export type StreamErrorCode = keyof typeof StreamErrorCodes

export interface StreamAvailability {
  available: boolean
  errors: StreamErrorCode[]
}

export interface StreamToken {
  token?: string
  errors: StreamErrorCode[]
}

export async function fetchStreamAvailability(
  profileId: string,
  streamId: string,
  options: APIRequestInit = {}
): Promise<StreamAvailability> {
  try {
    await fetch(`/api/v1/profiles/${profileId}/streams/${streamId}`, options)
  } catch (error: any) {
    const errors = error.response?.body?.errors || []
    if (Array.isArray(errors)) {
      return {
        available: false,
        errors: errors.map((value) => String(value)) as StreamErrorCode[],
      }
    }

    return { available: false, errors: ['0000'] }
  }

  return { available: true, errors: [] }
}

export async function fetchStreamToken(
  profileId: string,
  streamId: string,
  options: APIRequestInit = {}
): Promise<StreamToken> {
  try {
    const response = await fetch(
      `/api/v1/profiles/${profileId}/streams/${streamId}`,
      {
        ...options,
        method: 'POST',
      }
    )
    const token = response?.body?.token

    if (typeof token === 'string') {
      return { errors: [], token }
    } else {
      return { errors: ['0000'] }
    }
  } catch (error: any) {
    const errors = error.response?.body?.errors || []

    if (Array.isArray(errors)) {
      return {
        errors: errors.map((value) => String(value)) as StreamErrorCode[],
      }
    }

    return { errors: ['0000'] }
  }
}
