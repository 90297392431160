/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import triangle from '@styles/images/triangle.svg'

const sizes = {
  normal: {},
  large: {
    fontSize: [1, 2],
    py: ['1em', '1.5em'],
    px: '1em',
    mx: '18px',
    '&:before, &:after': {
      position: 'absolute',
      content: `''`,
      width: '18px',
      top: 0,
      bottom: 0,
      WebkitMask: `url(${triangle}) no-repeat center`,
      mask: `url(${triangle}) no-repeat center`,
      backgroundSize: '100% 100%',
      maskSize: '100% 100%',
    },
    '&:before': {
      left: '-18px',
    },
    '&:after': {
      right: '-18px',
      transform: 'scaleX(-1)',
    },
  },
  xlarge: {
    fontSize: [4, 5, 6, 6, 7],
    lineHeight: '1em',
    py: '0.15em',
    px: '0.5em',
    mx: ['8px', '11px', null, '16px', '25px'],
    '&:before, &:after': {
      position: 'absolute',
      content: `''`,
      width: ['8px', '11px', null, '16px', '25px'],
      top: 0,
      bottom: 0,
      WebkitMask: `url(${triangle}) no-repeat center`,
      mask: `url(${triangle}) no-repeat center`,
      backgroundSize: '100% 100%',
      maskSize: '100% 100%',
    },
    '&:before': {
      left: ['-8px', '-11px', null, '-16px', '-25px'],
    },
    '&:after': {
      right: ['-8px', '-11px', null, '-16px', '-25px'],
      transform: 'scaleX(-1)',
    },
  },
}

const styles = {
  fontSize: [0, 1],
  lineHeight: 1,
  position: 'relative',
  py: '0.67em',
  px: 2,
  mx: 10,
  borderRadius: 0,
  // display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  textTransform: 'uppercase',
  textAlign: 'center',

  '&:before, &:after': {
    position: 'absolute',
    content: `''`,
    width: '10px',
    top: 0,
    bottom: 0,
    WebkitMask: `url(${triangle}) no-repeat center`,
    mask: `url(${triangle}) no-repeat center`,
    backgroundSize: '100% 100%',
    maskSize: '100% 100%',
  },
  '&:before': {
    left: '-10px',
  },
  '&:after': {
    right: '-10px',
    transform: 'scaleX(-1)',
  },
}

const Badge = ({
  children,
  bg = 'black',
  color = 'primary',
  size = 'normal',
  ...rest
}: {
  children: React.ReactNode
  bg?: string
  color?: string
  size?: string
}) => {
  const sizeStyles = { ...styles, ...sizes[size] }

  sizeStyles['&:before'].bg = bg
  sizeStyles['&:after'].bg = bg

  return (
    <Box
      color={color}
      sx={{
        bg: bg,
        ...sizeStyles,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Badge
