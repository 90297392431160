/** @jsx jsx */
import { jsx, Flex, Text } from 'theme-ui'

import {
  KWAl,
  KW6,
  KW9,
  KW12,
  KW16,
  KWAngst,
  KWDrugs,
  KWGeweld,
  KWSex,
  KWTaal,
  KWThema,
} from '@components/Icons'
import { ShowObject } from '@api/resources'
import { getTypeColor } from '@ui/Item'

const KijkwijzerIcon = ({
  rating,
  color = 'primary',
  small = false,
  ...rest
}: {
  rating: string
  color: string
  small: boolean
  rest?: any
}) => {
  const size = small ? '22px' : '30px'

  const style = {
    mr: 1,
    flexShrink: 0,
  }

  switch (rating) {
    case '6':
      return <KW6 color={color} {...rest} size={size} sx={style} />
    case '9':
      return <KW9 color={color} {...rest} size={size} sx={style} />
    case '12':
      return <KW12 color={color} {...rest} size={size} sx={style} />
    case '16':
      return <KW16 color={color} {...rest} size={size} sx={style} />
    case 'AL':
      return <KWAl color={color} {...rest} size={size} sx={style} />
    case 'ANGST':
      return <KWAngst color={color} {...rest} size={size} sx={style} />
    case 'DRUGS':
      return <KWDrugs color={color} {...rest} size={size} sx={style} />
    case 'GEWELD':
      return <KWGeweld color={color} {...rest} size={size} sx={style} />
    case 'SEKS':
      return <KWSex color={color} {...rest} size={size} sx={style} />
    case 'TAALGEBRUIK':
      return <KWTaal color={color} {...rest} size={size} sx={style} />
    case 'DISCRIMINATIE':
      return <KWThema color={color} {...rest} size={size} sx={style} />
    default:
      return null
  }
}

const getIsFilm = (type: string | undefined) => {
  if (!type) return true

  switch (type.toLowerCase()) {
    case 'masterclass':
    case 'workshop':
    case 'tutorial':
    case 'tutorials':
    case 'medialab':
      return false
    default:
      return true
  }
}

const ItemMetadata = ({
  show,
  small = false,
  showIcons = true,
  compact = false,
  ...rest
}: {
  show: ShowObject
  small?: boolean
  showIcons?: boolean
  compact?: boolean
}) => {
  const { type, duration, language, parentalGuidance, genres } = show
  const isFilm = getIsFilm(type)

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyItems: 'flex-start',
        fontSize: [0, 1],
        lineHeight: '22px',
        color: 'gray',
        'svg + div': {
          pl: [1, 2],
        },
        m: 2,
        mx: 0,
        width: '100%',
        '> div span': {
          '&:not(:last-child)': {
            '&::after': {
              content: '"•"',
              px: 1,
            },
          },
        },
      }}
      {...rest}
    >
      {showIcons &&
        parentalGuidance &&
        parentalGuidance.map((rating) => (
          <KijkwijzerIcon
            key={rating}
            small={small}
            color={small ? 'gray' : 'black'}
            rating={rating}
          />
        ))}

      <Text variant="mono">
        {!compact && type && (
          <Text
            as="span"
            variant="mono"
            sx={{ color: getTypeColor(type, 'gray') }}
            {...rest}
          >
            {type}
          </Text>
        )}
        {genres && isFilm && (
          <Text as="span" variant="mono">
            {genres?.join(' / ')}
          </Text>
        )}
        {!!duration && isFilm && (
          <Text as="span" variant="mono">
            {duration}&nbsp;min
          </Text>
        )}
        {language && isFilm && !small && (
          <Text as="span" variant="mono">
            {language === 'nl_subbed' ? 'NL ondertiteld' : 'NL gesproken'}
          </Text>
        )}
      </Text>
    </Flex>
  )
}

export default ItemMetadata
