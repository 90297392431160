/** @jsx jsx */
import { jsx, Box, Flex, Image } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import Link from 'next/link'

import logo from '@styles/images/logo-cinekid-play.svg'

import { LinkButton } from '@ui/Button'

const NavBarProduct = ({
  backButtonTextColor = 'red',
  noBackButton,
}: {
  backButtonTextColor?: string
  noBackButton?: boolean
}) => {
  const index = useBreakpointIndex()
  const isMobile = index <= 2

  return (
    <Flex
      sx={{
        position: 'absolute',
        width: '100%',
        p: [2, 3],
        alignItems: 'center',
        minHeight: 80,
      }}
    >
      <Box sx={{ flexGrow: 1, width: '50%' }}>
        {!noBackButton &&
          (!isMobile ? (
            <LinkButton
              variant="transparent"
              label="terug"
              icon="arrowLeft"
              href="/"
              sx={{
                color: backButtonTextColor,
                display: 'inline-flex',
              }}
            />
          ) : (
            <LinkButton
              variant="transparent"
              label=""
              icon="arrowLeft"
              href="/"
              sx={{
                display: 'inline-flex',
              }}
            />
          ))}
      </Box>

      <Link href="/" passHref>
        <a
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            cursor: 'pointer',
          }}
        >
          <Image
            src={logo}
            sx={{
              display: 'block',
              maxWidth: ['12em', '18em'],
            }}
          />
        </a>
      </Link>

      <Box sx={{ flexGrow: 1, width: '50%' }} />
    </Flex>
  )
}

export default NavBarProduct
