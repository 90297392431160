import { nl } from 'date-fns/locale'
import {
  parseISO,
  format,
  addDays,
  isToday as isTd,
  isBefore as isBf,
  isAfter as isAf,
} from 'date-fns'

export const fallbackDate = '1990-01-01T00:00:00.000Z'

export const formatISO = (
  dateString: string,
  formatString = 'd LLLL – HH:mm'
) => {
  const date = parseISO(dateString)
  return format(date, formatString, { locale: nl })
}

export const isToday = (dateString: string) => {
  return isTd(parseISO(dateString))
}

export const getWeekDayHumanReadable = (dateString: string) => {
  return isToday(dateString) ? 'Vandaag' : formatISO(dateString, 'EEEE')
}

export const isBefore = (date: number | Date, dateToCompare: number | Date) => {
  return isBf(date, dateToCompare)
}

export const isAfter = (date: number | Date, dateToCompare: number | Date) => {
  return isAf(date, dateToCompare)
}

export const getFormatedDateString = (dateString: string) => {
  const time = formatISO(dateString, 'HH:mm')
  const future = addDays(new Date(), 6)

  if (isAfter(parseISO(dateString), future)) {
    return `${formatISO(dateString, 'd MMMM')} ${time}`
  } else {
    return `${getWeekDayHumanReadable(dateString)} ${time}`
  }
}
