/** @jsx jsx */
import React from 'react'
import { jsx, Box, Heading, Image } from 'theme-ui'
import Link from 'next/link'

const BannerWrapper = ({
  linkTo,
  children,
}: {
  linkTo?: string
  children: React.ReactNode
}) => {
  if (linkTo)
    return (
      <Link href={linkTo} passHref>
        <a
          sx={{
            textDecoration: 'none',
          }}
        >
          {children}
        </a>
      </Link>
    )

  return <React.Fragment>{children}</React.Fragment>
}

const Banner = ({
  title,
  subtitle,
  imageUrl,
  videoUrl,
  linkTo,
  bg = 'green',
  titleColor = 'blue',
  color = 'white',
  ...rest
}: {
  title: string
  subtitle: string
  imageUrl?: string
  videoUrl?: string
  linkTo?: string
  bg?: string
  titleColor?: string
  color?: string
}) => {
  return (
    <BannerWrapper linkTo={linkTo}>
      <Box
        sx={{
          bg: bg,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: ['column-reverse', 'row'],
        }}
        {...rest}
      >
        <Box
          color={color}
          sx={{
            display: 'flex',
            flex: 3,
            fontSize: [0, 1],
            lineHeight: 1,
            position: 'relative',
            py: [3, 6],
            px: [3, 5],
            borderRadius: 0,
            justifyContent: 'center',
            flexDirection: 'column',
            textTransform: 'uppercase',
          }}
        >
          <Heading
            as="h1"
            variant="heading.extraLarge"
            sx={{ color: titleColor }}
          >
            {title}
          </Heading>
          <Heading as="h2" variant="heading.large">
            {subtitle}
          </Heading>
        </Box>
        <Box sx={{ flex: 1, display: 'flex' }}>
          {imageUrl && <Image src={imageUrl} sx={{ flex: 1 }} />}
          {videoUrl && (
            <video
              src={videoUrl}
              sx={{ flex: 1 }}
              width="100%"
              autoPlay
              muted
              loop
            />
          )}
        </Box>
      </Box>
    </BannerWrapper>
  )
}

export default Banner
