import useSessionStore, { SessionStore } from '../session'

export const selectProfile = (store: SessionStore) => {
  if (store.profile) {
    const profiles = store.user?.profiles || []
    return profiles.find((profile) => profile.id === store.profile)
  } else {
    return undefined
  }
}

function useProfile() {
  return useSessionStore(selectProfile)
}

export default useProfile
