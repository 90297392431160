import { useState, useCallback, useRef, useEffect } from 'react'
import useSessionStore, { SessionStore } from '../session'
import { selectProfile } from './useProfile'
import * as audio from '@components/3d/Audio'

export const selectSetFavorite = (store: SessionStore) => {
  return store.actions.setFavorited
}

export const selectProfileFavorites = (store: SessionStore) => {
  return selectProfile(store)?.favorites
}

function useFavoriteStatus(id?: string) {
  const isMounted = useRef(true)
  const favorites = useSessionStore(selectProfileFavorites) || []
  const setFavorited = useSessionStore(selectSetFavorite)
  const isFavorited = !!id && favorites.indexOf(id) !== -1
  const [isLoading, setIsLoading] = useState(false)

  const onToggle = useCallback(() => {
    if (isLoading || !id) return

    audio.favorite.play()

    setIsLoading(true)
    setFavorited(id, !isFavorited).finally(() => {
      if (!isMounted.current) return
      setIsLoading(false)
    })
  }, [isFavorited, isLoading, setFavorited, id])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return { isLoading, isFavorited, onToggle: isLoading ? undefined : onToggle }
}

export default useFavoriteStatus
