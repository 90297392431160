/** @jsx jsx */
import * as React from 'react'
import { jsx } from 'theme-ui'
import { keyframes } from '@emotion/core'

const popAnimation = keyframes`
0% {
  transform: translate(-50%, -60%) scale(0);
}
50% {
  transform: translate(-50%, -60%) scale(1.3);
}
100% {
  transform: translate(-50%, -60%) scale(1);
}`

const NotificationDot = () => (
  <div
    sx={{
      position: 'absolute',
      width: '0.5em',
      height: '0.5em',
      borderRadius: '1em',
      bg: 'red',
      right: '-0.2em',
      transform: 'translate(-50%, -60%) scale(0)',
      animation: `${popAnimation} 0.3s ease 1s 5`,
      animationFillMode: 'forwards',
    }}
  />
)

export default NotificationDot
