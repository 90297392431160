import { Howl } from 'howler'
import loopAudio from './audio/bg-fx-loop.mp3'
import loopAudio2 from './audio/bg-scape-loop.mp3'
import flyingLoopAudio from './audio/flying-loop.mp3'
import flyingStartAudio from './audio/flying-start.mp3'
import flyingEndAudio from './audio/flying-end.mp3'
import hoverAudio from './audio/hover.mp3'
import favoriteAudio from './audio/favorite.mp3'
import trophyAudio from './audio/trophy.mp3'
import spacejunkAudio from './audio/spacejunk.mp3'
import logbookAudio from './audio/logbook.mp3'
import hudAudio from './audio/hud.mp3'
import selectSuitAudio from './audio/select-suit.mp3'
import landingAudio from './audio/landing.mp3'
import pokeAudio from './audio/poke.mp3'
import hyperAudio from './audio/hyperjump.mp3'

const loop = new Howl({
  src: loopAudio,
  loop: true,
  preload: true,
})
const loop2 = new Howl({
  src: loopAudio2,
  loop: true,
  preload: true,
})
const flyingLoop = new Howl({
  src: flyingLoopAudio,
  loop: true,
  preload: true,
})
const flyingStart = new Howl({
  src: flyingStartAudio,
  loop: false,
  preload: true,
  onend: () => {
    flyingLoop.stop()
    flyingLoop.loop(true)
    flyingLoop.play()
  },
})
const flyingEnd = new Howl({ src: flyingEndAudio, loop: false, preload: true })

const hover = new Howl({ src: hoverAudio, preload: true })
const favorite = new Howl({ src: favoriteAudio, preload: true })
const trophy = new Howl({ src: trophyAudio, preload: true })
const spacejunk = new Howl({ src: spacejunkAudio, preload: true })
const logbook = new Howl({ src: logbookAudio, preload: true })
const landing = new Howl({ src: landingAudio, preload: true })
const hud = new Howl({ src: hudAudio, preload: true })
const selectSuit = new Howl({ src: selectSuitAudio, preload: true })
const poke = new Howl({ src: pokeAudio, preload: true })
const hyper = new Howl({ src: hyperAudio, preload: true })

export {
  loop,
  loop2,
  flyingLoop,
  flyingStart,
  flyingEnd,
  hover,
  favorite,
  trophy,
  spacejunk,
  logbook,
  hud,
  landing,
  selectSuit,
  poke,
  hyper,
}
