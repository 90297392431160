/** @jsx jsx */
import { jsx, Box, Button } from 'theme-ui'

const MenuButton = ({ isOpen, color = 'yellow', ...rest }) => {
  const variant = isOpen ? 'menu--open' : 'menu'
  return (
    <Button
      variant={variant}
      sx={{
        '> div': {
          bg: color,
        },
      }}
      {...rest}
    >
      <Box />
      <Box />
      <Box />
    </Button>
  )
}

export default MenuButton
