/** @jsx jsx */
import { jsx, Text, Grid, Heading } from 'theme-ui'
import { ellipsis } from 'polished'
import { useEffect } from 'react'
import difference from 'lodash/difference'

import { Modal } from '@components/Modal'
import { formatISO } from '@utils/date'
import useProfile from '@api/hooks/useProfile'
import useSession from '@api/hooks/useSession'
import useUIStore from '@stores/ui'

export const getTrophyName = (type: string) => {
  const types = {
    VIEW_ONE_PLANET: 'Éen planeet bekeken',
    VIEW_THREE_PLANETS: '3 planeten bekeken',
    VIEW_FIVE_PLANETS: '5 planeten bekeken',
    VIEW_TEN_PLANETS: '10 planeten bekeken',
    VIEW_TWENTY_PLANETS: '20 planeten bekeken',
    //  Shows
    VIEWED_ONE_SHOW: 'Éen film bekeken',
    VIEWED_THREE_SHOWS: '3 films bekeken',
    VIEWED_FIVE_SHOWS: '5 films bekeken',
    VIEWED_TEN_SHOWS: '10 films bekeken',

    // Workshops
    VIEWED_ONE_WORKSHOP: 'Éen workshop gevolgd',
    VIEWED_THREE_WORKSHOPS: '3 workshops gevolgd',
    VIEWED_FIVE_WORKSHOPS: '5 workshops gevolgd',

    // Medialab
    VIEWED_ONE_MEDIALAB: 'Éen medialab bekeken',
    VIEWED_THREE_MEDIALABS: '3 medialabs bekeken',
    VIEWED_FIVE_MEDIALABS: '5 medialabs bekeken',

    // Space suit
    CHANGE_AVATAR_ONCE: 'Ruimtepak aangepast',
    CHANGE_AVATAR_THRICE: 'Ruimtepak expert',

    // Junk
    COLLECT_ONE_JUNK: 'Ruimteafval gevonden',
    // "COLLECT_THREE_JUNKS": ''
    COLLECT_FIVE_JUNKS: 'Ruimte schoonmaker',
    COLLECT_TEN_JUNKS: 'Ruimteafval expert',
  }
  return types[type] || type
}

const Trophy = ({ item }) => {
  if (item.type === 'COLLECT_THREE_JUNKS') return null // no image available
  const name = getTrophyName(item.type)
  return (
    <Grid
      sx={{
        justifyContent: 'center',
        alignContent: 'stretch',
        alignItems: 'stretch',
        rowGap: 2,
      }}
    >
      <img
        src={`/images/trophies/${item.type}.png`}
        sx={{ display: 'block', maxWidth: '100%' }}
      />
      <Text variant="mono.body" sx={{ textAlign: 'center', ...ellipsis() }}>
        {name}
      </Text>
      <Text
        variant="mono"
        sx={{ textAlign: 'center', fontSize: 0, ...ellipsis() }}
      >
        {formatISO(item.collectedAt, undefined)}
      </Text>
    </Grid>
  )
}

export const Trophies = () => {
  const profile = useProfile()
  const currentModal = useUIStore((state) => state.modal)
  const hasNewTrophy = useUIStore((state) => state.hasNewTrophy)
  const setNewTrophy = useUIStore((state) => state.actions.setNewTrophy)
  const { isLoading } = useSession()

  useEffect(() => {
    if (hasNewTrophy && currentModal === 'trophies') {
      setNewTrophy(false)
    }
  }, [hasNewTrophy, setNewTrophy, currentModal])

  if (!profile) return null

  const junk = profile.achievements.filter(
    (a) => a.type !== 'COLLECT_ONE_JUNK' && a.type.indexOf('_JUNK_') !== -1
  )
  const trophies = difference(profile.achievements, junk, 'type')

  return (
    <Modal
      aria-labelledby="Trofeeën"
      name="trophies"
      backgroundColor="navy3d"
      borderColor="blue"
      backgroundImage="planet"
      isLoading={isLoading}
    >
      <Grid>
        <Heading as="h2" variant="mono.body" sx={{ color: 'yellow' }}>
          Trofeeën
        </Heading>
        {trophies.length === 0 ? (
          <Text sx={{ mt: 5 }}>Nog geen trofeeën verzameld.</Text>
        ) : (
          <Grid
            sx={{
              flexWrap: 'wrap',
              gridTemplateColumns: [
                '1fr',
                '1fr 1fr',
                'repeat(3, 1fr)',
                'repeat(4, 1fr)',
              ],
              gridTemplateRows: 'auto',
              alignItems: 'stretch',
              justifyContent: 'flex-start',
              justifyItems: 'stretch',
            }}
          >
            {trophies.map((trophy, i) => (
              <Trophy key={`trophy-${i}`} item={trophy} />
            ))}
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}
