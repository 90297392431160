/** @jsx jsx */
import { jsx, Text, Grid, Flex } from 'theme-ui'
import { useCallback, useEffect } from 'react'

import { Modal } from '@components/Modal'
import { Button, LinkButton } from '@ui/Button'
import useUIStore from '@stores/ui'

const OnlineResourceModal = () => {
  const item = useUIStore((state) => state.hasOnlineResource)
  const toggleModal = useUIStore((state) => state.actions.toggleModal)
  const setOnlineResource = useUIStore(
    (state) => state.actions.setOnlineResource
  )

  useEffect(() => {
    return () => {
      setOnlineResource(false)
    }
  }, [setOnlineResource])

  const handleHide = useCallback(() => {
    toggleModal(undefined)
  }, [toggleModal])

  const handleClose = useCallback(() => {
    setTimeout(() => {
      toggleModal(undefined)
    }, 500)
  }, [toggleModal])

  return (
    <Modal
      aria-labelledby="Externe website"
      name="onlineResource"
      backgroundColor="navy3d"
      borderColor="blue"
      backgroundImage="planet"
      narrow={true}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Grid
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <Text variant="body">
          Je gaat nu Cinekid Play verlaten. Kom je snel weer terug om op
          ontdekkingstocht te gaan?
        </Text>

        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            mb: 6,
            flexWrap: 'wrap',
            a: {
              minWidth: '140px',
            },
          }}
        >
          <Button
            onClick={handleHide}
            variant="primary"
            sx={{ display: 'inline-block', flexGrow: 0, mb: 3, mr: 3 }}
          >
            Blijf op Cinekid Play
          </Button>
          <LinkButton
            onClick={handleClose}
            data-external
            variant="red"
            href={item?.onlineResourceURL}
            target="_blank"
            sx={{ display: 'inline-block', flexGrow: 0, mb: 3 }}
          >
            Ja, is goed!
          </LinkButton>
        </Flex>
      </Grid>
    </Modal>
  )
}

export default OnlineResourceModal
