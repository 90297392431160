/** @jsx jsx */
import { useRef, useEffect } from 'react'
import { jsx, Heading, Text } from 'theme-ui'
import useUIStore from '@stores/ui'

import useFavorites from '@api/hooks/useFavorites'
import { Modal } from '@components/Modal'
import { Icon } from '@components/Icons'
import ItemsList from '@ui/ItemsList'

const Favorites = ({ ...rest }) => {
  const isFresh = useRef(false)
  const currentModal = useUIStore((state) => state.modal)
  const { shows, refresh } = useFavorites(false)

  useEffect(() => {
    if (!isFresh.current && currentModal === 'favorites') {
      isFresh.current = true
      refresh()
    } else {
      isFresh.current = false
    }
  }, [refresh, currentModal])

  return (
    <Modal
      aria-labelledby="Favorieten"
      name="favorites"
      backgroundColor="red"
      backgroundImage="grid"
      {...rest}
    >
      <Heading as="h2" variant="mono.body" sx={{ mb: 5 }}>
        <Icon
          name="HeartIcon"
          color="yellow"
          sx={{ verticalAlign: 'middle', fontSize: '1.3em', mt: '-0.15em' }}
        />{' '}
        Favorieten
      </Heading>

      {shows.length === 0 ? (
        <Text sx={{ my: 6 }}>
          Nog geen favorieten.
          <br />
          Geef films een hartje om ze tussen jouw favorieten te zetten!
        </Text>
      ) : (
        <ItemsList verticalList shows={shows} />
      )}
    </Modal>
  )
}

export default Favorites
