/** @jsx jsx */
import { useState, useCallback } from 'react'
import { jsx, Flex, Text } from 'theme-ui'
import Link from 'next/link'
import { a, useTransition } from 'react-spring'

import useAnalyticsStore, { AnalyticsStore } from '@stores/analytics'
import { getThemeColor } from '@utils/getThemeColor'
import { Button } from '@components/ui/Button'

const AnimatedFlex = a(Flex)
const selectRequestedCookies = (store: AnalyticsStore) => store.requested
const selectEnableCookies = (store: AnalyticsStore) => store.actions.setEnabled

export const CookieNotice = ({ ...rest }) => {
  const didRequest = useAnalyticsStore(selectRequestedCookies)
  const setCookiesEnabled = useAnalyticsStore(selectEnableCookies)
  const [show, setShow] = useState(!didRequest)
  const transition = useTransition(show, {
    from: { y: `100%` },
    enter: { y: `0%` },
    leave: { y: `100%` },
  })

  const onDismiss = useCallback(() => {
    setCookiesEnabled(false)
    setShow(false)
  }, [setCookiesEnabled, setShow])

  const onAccept = useCallback(() => {
    setCookiesEnabled(true)
    setShow(false)
  }, [setCookiesEnabled, setShow])

  return transition(
    ({ y }, item) =>
      item && (
        <AnimatedFlex
          style={{ y }}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
            bg: 'navy',
            borderTop: `2px solid ${getThemeColor('blue')}`,
            flexDirection: ['column', 'row'],
            p: 3,
            lineHeight: '1em',
            alignItems: 'center',
            justifyContent: 'space-between',
            a: {
              color: 'yellow',
            },
          }}
          {...rest}
        >
          <Text
            variant="mono"
            color="white"
            sx={{
              m: 0,
              mb: [3, 0],

              lineHeight: '1.2em',
              textAlign: ['center', 'left'],
            }}
          >
            Wij nemen de privacy van onze bezoekers serieus. Op deze website
            worden functionele, analytische en tracking cookies gebruikt om uw
            bezoek aan deze website zo gebruikersvriendelijk mogelijk te maken.{' '}
            <Link href=" /pagina/privacy" passHref>
              <a>Lees&nbsp;meer</a>
            </Link>
          </Text>
          <Flex
            sx={{
              alignItems: 'center',
              cursor: 'pointer',
              flexShrink: 0,
              ml: 2,
            }}
          >
            <Button
              onClick={onDismiss}
              size="small"
              color="gray"
              variant="lightgray"
              sx={{ flexShrink: 0, mr: 3 }}
            >
              Nee, liever niet
            </Button>
            <Button onClick={onAccept} size="small" sx={{ flexShrink: 0 }}>
              Prima
            </Button>
          </Flex>
        </AnimatedFlex>
      )
  )
}
