/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

const FestivalBadge = ({
  children,
  bg = 'green',
  color = 'white',
  ...rest
}: {
  children: React.ReactNode
  bg?: string
  color?: string
}) => {
  return (
    <Box
      color={color}
      sx={{
        bg: bg,
        ...{
          fontSize: [0, 1],
          lineHeight: 1,
          position: 'relative',
          py: 2,
          px: 4,
          borderRadius: 0,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          textTransform: 'uppercase',
          textAlign: 'center',
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default FestivalBadge
