/** @jsx jsx */
import { jsx, Flex, Image, Grid } from 'theme-ui'
import Link from 'next/link'

import logo from '@styles/images/logo.svg'

import { Button, LinkButton } from '@ui/Button'
import useAuthorized from '@api/hooks/useAuthorized'
import useUIStore from '@stores/ui'
import { useViewLimit } from '@hooks/useViewLimit'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import MenuButton from '../MenuButton'
import useHistoryStore from '@stores/history'
import { useBreakpointIndex } from '@theme-ui/match-media'
import NotificationDot from '@components/NotificationDot'

const NavBar = () => {
  const isAuthorized = useAuthorized()
  const router = useRouter()
  const previousPath = useHistoryStore((state) => state.previousPath)
  const hasUnread = useUIStore((s) => s.hasUnread)
  const { viewLimitDisabled } = useViewLimit()

  const toggleModal = useUIStore((s) => s.actions.toggleModal)

  const isProgramPage =
    router.pathname.indexOf('bekijk') !== -1 ||
    ['/live'].includes(router.pathname)

  const onBack = useCallback(
    (e: React.MouseEvent) => {
      if (e) e.preventDefault()

      previousPath ? router.back() : router.push('/')
    },
    [previousPath, router]
  )
  const breakpointIndex = useBreakpointIndex()

  const isMobile = breakpointIndex <= 0
  const showLogBookButton = !isProgramPage && !isMobile

  return (
    <Grid
      sx={{
        gridTemplateAreas: `'left center right'`,
        gridTemplateColumns: '1fr max-content 1fr',
        width: '100%',
        p: 10,
        position: 'relative',
        pb: 0,
        zIndex: 910,
      }}
    >
      <Flex
        sx={{
          gridArea: 'left',
        }}
      >
        <React.Fragment>
          {isProgramPage && (
            <Button
              variant={isMobile ? 'transparent' : 'dark'}
              label={isMobile ? undefined : 'Terug'}
              icon="arrowLeft"
              onClick={onBack}
            />
          )}

          {!isAuthorized && isMobile && !isProgramPage && (
            <LinkButton
              variant="secondary"
              label="Aanmelden"
              sx={{
                marginRight: '16px',
              }}
              href="/ouders"
            />
          )}

          {!isProgramPage && !isMobile && (
            <LinkButton
              variant="primary"
              label="Ontdekken"
              icon="planet"
              href="/ontdekken"
              sx={{
                marginRight: '16px',
              }}
            />
          )}
          {showLogBookButton && (
            <Button
              variant="yellow"
              onClick={() => {
                toggleModal('feed')
              }}
            >
              Nieuws
              {hasUnread && <NotificationDot />}
            </Button>
          )}
        </React.Fragment>
      </Flex>
      <Flex
        sx={{
          gridArea: 'center',
          alignItems: 'center',
          pb: isProgramPage ? 2 : 0,
        }}
      >
        <Link href="/" passHref>
          <a>
            <Image
              src={logo}
              sx={
                !isAuthorized && isMobile
                  ? {
                      '@media screen and (max-width: 25em)': {
                        display: 'none',
                      },
                    }
                  : undefined
              }
            />
          </a>
        </Link>
      </Flex>
      <Flex
        sx={{
          gridArea: 'right',
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      >
        {isAuthorized ? (
          <Button
            variant="secondary"
            label="Favorieten"
            icon="heart"
            sx={{
              display: ['none', 'none', 'flex'],
            }}
            onClick={() => toggleModal('favorites')}
          />
        ) : viewLimitDisabled ? null : (
          <LinkButton
            variant="secondary"
            label="Aanmelden"
            sx={{
              display: ['none', 'flex', 'flex'],
            }}
            href="/ouders"
          />
        )}
        <Flex
          sx={{
            ml: [null, 3, 3],
            mr: 2,
            alignItems: 'center',
            height: 'calc(100% - 10px)',
          }}
        >
          <MenuButton isOpen={false} onClick={() => toggleModal('menu')} />
        </Flex>
      </Flex>
    </Grid>
  )
}

export default NavBar
