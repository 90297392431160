import fetch from '../fetch'
import parse, { ResourceAttributes } from '../parse'
import { parseImage } from './image'
import { parseShow } from './shows'

export function parseCollection(attributes: ResourceAttributes) {
  return {
    id: attributes.id(),
    title: attributes.string('title'),
    introduction: attributes.optional.string('introduction'),
    subtitle: attributes.optional.string('subtitle'),
    description: attributes.string('description'),
    curated: attributes.boolean('curated'),
    publishAt: attributes.date('publishAt'),
    expiresAt: attributes.optional.date('expiresAt'),
    image: attributes.optional.object('image', (object: any) => {
      return parse.object(object, parseImage)
    }),
    videoUrl: attributes.optional.string('videoUrl'),
    planet: attributes.object('planet', (planet: any) => {
      return parse.object(planet, (planetAttributes) => ({
        theme: planetAttributes.string('theme'),
        variant: planetAttributes.string('variant'),
        color: planetAttributes.string('color'),
        position: planetAttributes.number('position'),
      }))
    }),
    shows: attributes.optional.included('shows', (shows: any) => {
      return parse.many(shows, parseShow)
    }),
  }
}

export type CollectionObject = ReturnType<typeof parseCollection>

export async function fetchCollection(id: string) {
  const response = await fetch(`/api/v1/collections/${id}`)
  return parse.one(response.body, parseCollection)
}

export async function fetchCollections() {
  const response = await fetch(`/api/v1/collections`)
  return parse.many(response.body, parseCollection)
}
