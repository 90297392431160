/** @jsx jsx */
import React from 'react'
import { Flex, Image, jsx } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import useAuthorized from '@api/hooks/useAuthorized'
import logo from '@styles/images/logo.svg'
import playLogo from '@styles/images/logo-cinekid-play.svg'
import { Button, LinkButton } from '@components/ui/Button'
import MenuButton from '@components/ui/MenuButton'

import useUIStore from '@stores/ui'
import useAvatar from '@api/hooks/useAvatar'
import { Block, Side } from './HUDElements'
import use3DStore from '@stores/3d'
import NotificationDot from '@components/NotificationDot'

const DynamicUniverseMap = dynamic(() => import('../../3d/UniverseMap'), {
  ssr: false,
})

export default function HUD() {
  const { colors } = useAvatar()
  const router = useRouter()

  const isDiscoverPage = router.pathname === '/ontdekken'

  const hideHUD = !isDiscoverPage
  const elOptions = {
    bColor: colors[0],
    hide: hideHUD,
    bW: 2,
    height: 58,
    angle: 25,
    bwRatio: 0.4,
  }

  return (
    <div
      sx={{
        position: 'fixed',
        p: hideHUD ? '10px' : 0,
        pb: 0,
        zIndex: 910,
        width: '100%',
        height: '100%',
        display: 'grid',
        borderTop: !hideHUD ? '10px solid black' : undefined,
        borderBottom: !hideHUD ? '10px solid black' : undefined,
        borderLeft: !hideHUD ? ['none', null, '10px solid black'] : undefined,
        borderRight: !hideHUD ? ['none', null, '10px solid black'] : undefined,
        pointerEvents: 'none',
        gridTemplateAreas: `
        'top'
        'center'
        'bottom'
        `,
        gridTemplateColumns: '100%',
        gridTemplateRows: 'max-content 1fr max-content',
      }}
    >
      <TopNavHud elOptions={elOptions} />

      {/* MIDDLE */}
      <div
        sx={{
          display: 'grid',
          gridTemplateAreas: `'leftBorder empty empty empty rightBorder'`,
        }}
      >
        <Side
          right
          sx={{
            gridArea: 'leftBorder',
            width: 0,
            display: ['none', null, 'block'],
          }}
          {...elOptions}
        />
        <div sx={{ gridArea: 'empty' }} />
        <Side
          {...elOptions}
          left
          sx={{
            gridArea: 'rightBorder',
            width: 0,
            justifySelf: 'flex-end',
            display: ['none', null, 'block'],
          }}
        />
      </div>

      {!hideHUD && <BottomNav elOptions={elOptions} />}
    </div>
  )
}

const TopNavHud = ({ elOptions }) => {
  const isAuthorized = useAuthorized()
  const router = useRouter()

  const hasUnread = useUIStore((s) => s.hasUnread)
  const toggleModal = useUIStore((s) => s.actions.toggleModal)
  const breakpointIndex = useBreakpointIndex()

  const isMobile = breakpointIndex <= 1
  const isProgramPage =
    router.pathname.indexOf('bekijk') !== -1 ||
    ['/live'].includes(router.pathname)

  return (
    <div
      sx={{
        display: 'grid',
        width: '100%',
        gridTemplateAreas: [`'left right'`, null, `'left  center  right'`],

        gridTemplateColumns: ['1fr 1fr', null, '1fr 90px  1fr'],
      }}
    >
      <Flex
        style={{
          gridArea: 'left',
        }}
      >
        <Block
          {...elOptions}
          top
          right
          style={{
            width: [58, 165, !isAuthorized ? 165 : 272],
          }}
        >
          <LinkButton
            variant="primary"
            label={breakpointIndex > 0 ? 'overzicht' : ''}
            icon="search"
            href="/overzicht"
          />

          {isAuthorized && !isMobile && (
            <Button
              variant="yellow"
              onClick={() => {
                toggleModal('feed')
              }}
            >
              Logboek
              {hasUnread && <NotificationDot />}
            </Button>
          )}
        </Block>
        <Side bottom sx={{ flexGrow: 1, height: 0 }} {...elOptions} />
      </Flex>
      <Block
        top
        right
        left
        {...elOptions}
        style={{
          display: ['none', null, 'flex'],
          gridArea: 'center',
        }}
        innerStyle={{
          display: 'flex',
          alignItems: 'center',
          pb: isProgramPage ? 2 : 0,
        }}
      >
        <Link href="/ontdekken" passHref>
          <a>
            <Image
              sx={{ maxWidth: isProgramPage ? '12em' : 'unset' }}
              src={isProgramPage ? playLogo : logo}
            />
          </a>
        </Link>
      </Block>
      <Flex
        style={{
          gridArea: 'right',
        }}
      >
        <Side bottom sx={{ flexGrow: 1, height: 0 }} {...elOptions} />
        <Block
          {...elOptions}
          top
          left
          style={{
            width: isAuthorized
              ? ['58px', '58px', '240px']
              : ['58px', '58px', '200px'],
          }}
        >
          {/* <LinkButton
            variant="primary"
            label="live"
            icon="live"
            sx={{
              display: isAuthorized
                ? ['none', 'none', 'none', 'none', 'flex']
                : ['none', 'none', 'flex'],
            }}
            href="/live"
          /> */}

          {isAuthorized ? (
            <Button
              variant="secondary"
              label="Favorieten"
              icon="heart"
              sx={{
                display: ['none', 'none', 'flex'],
              }}
              onClick={() => toggleModal('favorites')}
            />
          ) : (
            <LinkButton
              variant="secondary"
              label="Aanmelden"
              sx={{
                display: ['none', 'none', 'flex'],
              }}
              href="/ouders"
            />
          )}
          <Flex
            sx={{
              ml: [null, null, 3],
              mr: 2,
              alignItems: 'center',
              height: 'calc(100% - 10px)',
            }}
          >
            <MenuButton isOpen={false} onClick={() => toggleModal('menu')} />
          </Flex>
        </Block>
      </Flex>
    </div>
  )
}
const BottomNav = ({ elOptions }) => {
  const isAuthorized = useAuthorized()
  const router = useRouter()
  const toggleModal = useUIStore((s) => s.actions.toggleModal)
  const hasNewTrophy = useUIStore((s) => s.hasNewTrophy)
  const toggleSound = use3DStore((s) => s.actions.toggleSound)
  const hasSound = use3DStore((s) => s.sound)
  const breakpointIndex = useBreakpointIndex()
  const hasUnread = useUIStore((s) => s.hasUnread)

  const isMobile = breakpointIndex <= 1
  const isDiscoverPage = router.pathname === '/ontdekken'

  return (
    <div
      sx={{
        display: 'grid',
        gridTemplateAreas: [
          `'bottomSmall'`,
          `'bottomSmall'`,
          `'leftBottom centerBottom rightBottom'`,
        ],
        gridTemplateColumns: [
          'auto',
          'auto',
          `minmax(${isAuthorized ? 530 : 200}px, 1fr) max-content 1fr`,
        ],
      }}
    >
      {!isMobile ? (
        <React.Fragment>
          <Flex
            style={{
              gridArea: 'leftBottom',
            }}
          >
            <Block
              right
              style={{
                width: isAuthorized ? 470 : 155,
              }}
              {...elOptions}
            >
              <Button
                variant="dark"
                label="Planeten"
                icon="planet"
                onClick={() => {
                  toggleModal('index')
                }}
              />
              {isAuthorized && (
                <React.Fragment>
                  <LinkButton
                    icon="suit"
                    variant="dark"
                    label="Ruimtepak"
                    href="/ruimtepak"
                  />
                  <Button
                    onClick={() => {
                      toggleModal('trophies')
                    }}
                    icon="usb"
                    variant="dark"
                  >
                    <span sx={{ display: 'inline-block', ml: '0.4em' }}>
                      Trofeeën
                    </span>
                    {hasNewTrophy && <NotificationDot />}
                  </Button>
                </React.Fragment>
              )}
            </Block>
            <Side
              top
              sx={{
                flexGrow: 1,
                height: 0,
                alignSelf: 'flex-end',
              }}
              {...elOptions}
            />
          </Flex>
          <Block
            right
            left
            style={{
              gridArea: 'centerBottom',
              width: '16vw',
              maxWidth: '11vh',
            }}
            {...elOptions}
          >
            {isDiscoverPage && <DynamicUniverseMap />}
          </Block>
          <Flex style={{ gridArea: 'rightBottom' }}>
            <Side
              top
              sx={{
                flexGrow: 1,
                height: 0,
                alignSelf: 'flex-end',
              }}
              {...elOptions}
            />
            <Block left style={{ width: 138 }} {...elOptions}>
              <Button
                onClick={() => {
                  toggleModal('help')
                }}
                variant="dark"
                label="help"
              />
              <Button
                onClick={() => {
                  toggleSound()
                }}
                icon={hasSound ? 'sound' : 'muted'}
                variant="dark"
              />
            </Block>
          </Flex>
        </React.Fragment>
      ) : (
        <Block
          {...elOptions}
          style={{ gridArea: 'bottomSmall' }}
          innerStyle={{ width: '100%', pb: [1, 0] }}
        >
          <Button
            variant="dark"
            icon="planet"
            label={isAuthorized ? '' : 'planeten'}
            onClick={() => {
              toggleModal('index')
            }}
            sx={{ fontSize: [1, 2] }}
          />
          {isAuthorized && (
            <React.Fragment>
              <Button
                onClick={() => {
                  toggleModal('trophies')
                }}
                icon="usb"
                variant="dark"
                sx={{ fontSize: [1, 2] }}
              >
                {hasNewTrophy && <NotificationDot />}
              </Button>
              <LinkButton
                icon="suit"
                variant="dark"
                href="/ruimtepak"
                sx={{ fontSize: [1, 2] }}
              />
              <Button
                variant="dark"
                onClick={() => {
                  toggleModal('feed')
                }}
                sx={{ fontSize: [1, 2] }}
              >
                Logboek
                {hasUnread && <NotificationDot />}
              </Button>
            </React.Fragment>
          )}

          <Button
            onClick={() => {
              toggleModal('help')
            }}
            label="help"
            variant="dark"
            sx={{ fontSize: [1, 2] }}
          />
          <Button
            onClick={() => {
              toggleSound()
            }}
            icon={hasSound ? 'sound' : 'muted'}
            variant="dark"
            sx={{ fontSize: [1, 2] }}
          />

          {/* {isAuthorized && (
            <Button
              variant="dark"
              icon="heart"
              sx={{ fontSize: [1, 2] }}
              onClick={() => toggleModal('favorites')}
            />
          )} */}
        </Block>
      )}
    </div>
  )
}
