/** @jsx jsx */
import { useCallback } from 'react'
import { jsx, Flex, Text } from 'theme-ui'

import useFavoriteStatus from '@api/hooks/useFavoriteStatus'
import { Button } from '@ui/Button'

export interface AddToFavoritesProps {
  showId?: string
  isSmall?: boolean
  likes?: number
}

export interface AddToFavoritesStatusProps {
  isLoading?: boolean
  isActive?: boolean
  onToggle?: (e: React.MouseEvent) => void
}

const AddToFavorites = ({
  showId,
  isSmall = false,
  likes = null,
  ...rest
}: AddToFavoritesProps) => {
  const { isLoading, isFavorited, onToggle } = useFavoriteStatus(showId)
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (e) e.preventDefault()
      onToggle && onToggle()
    },
    [onToggle]
  )

  return (
    <Button
      variant={isFavorited ? 'secondary' : 'lightgray'}
      disabled={isLoading}
      onClick={onClick}
      sx={{
        display: 'flex',
        p: 0,
        maxWidth: '10em',
        height: isSmall ? '2.6em' : '8em',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          '.plus, .heart': {
            fill: isFavorited ? 'yellow' : 'red',
          },
        },
      }}
      {...rest}
    >
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Flex sx={{ alignItems: 'center' }}>
          <Text
            mr={0}
            color={isFavorited ? 'yellow' : 'gray'}
            sx={{ fontSize: isSmall ? 3 : 4, ml: isSmall ? 1 : 0 }}
          >
            {isFavorited ? likes + 1 : likes}
          </Text>
          <svg
            width={isSmall ? '2.5em' : '4em'}
            height={isSmall ? '2.5em' : '4em'}
            fill="none"
            viewBox="0 0 44 44"
          >
            <title>Toevoegen aan favorieten</title>
            <path
              fillRule="evenodd"
              d="M11.62 11.962c.873-.95 2.331-.95 3.203.001l.003.003 4.176 4.583 4.176-4.583.003-.003c.871-.95 2.33-.95 3.202-.001l.002.001 6.271 6.807.016.018c.734.86.835 2.266-.085 3.17L20.59 35.036c-.872.95-2.331.95-3.203 0L5.362 21.929a2.365 2.365 0 010-3.175l.001-.002 6.256-6.789.001-.001zm1.6 1.954l-5.922 6.426 11.69 12.743L30.69 20.326l-5.907-6.41-3.989 4.377-.003.003c-.988 1.077-2.593 1.077-3.58 0l-.003-.003-3.99-4.377z"
              clipRule="evenodd"
              className="heart"
              sx={{
                fill: isFavorited ? 'yellow' : 'gray',
                transform: isFavorited ? 'translateX(3px)' : '',
              }}
            />
            {!isFavorited && (
              <path
                fillRule="evenodd"
                d="M39.5 9a1 1 0 10-2 0v3.5H34a1 1 0 100 2h3.5V18a1 1 0 102 0v-3.5H43a1 1 0 100-2h-3.5V9z"
                clipRule="evenodd"
                className="plus"
                sx={{
                  fill: isFavorited ? 'yellow' : 'gray',
                  transform: 'scale(1)',
                  transformOrigin: 'center center',
                  transition: 'transform 0.2s ease',
                }}
              />
            )}
          </svg>
        </Flex>

        {!isSmall && (
          <Text
            variant="mono"
            sx={{ color: isFavorited ? 'white' : 'gray', lineHeight: '1.2em' }}
          >
            {isFavorited
              ? `Verwijder uit favorieten`
              : `Toevoegen aan favorieten`}
          </Text>
        )}
      </Flex>
    </Button>
  )
}

export default AddToFavorites
