import useSessionStore, { SessionStore } from '../session'

const selectIsAuthorized = (store: SessionStore) => store.isAuthorized
const selectIsLoading = (store: SessionStore) => store.isLoading

// We're expecting most users to be authorized
function useAuthorized() {
  const isAuthorized = useSessionStore(selectIsAuthorized)
  const isLoading = useSessionStore(selectIsLoading)

  return isLoading || isAuthorized
}

export default useAuthorized
