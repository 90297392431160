/** @jsx jsx */
import React from 'react'
import { ShowObject } from '@api/resources'
import { jsx, Grid, Box } from 'theme-ui'
import { slice } from 'lodash'
import Item from '@ui/Item'
import Banner from '@ui/Banner'
import useAuthorized from '@api/hooks/useAuthorized'

const ItemsList = ({
  shows,
  verticalList,
  showBanners = false,
  ...rest
}: {
  verticalList?: boolean
  showBanners?: boolean
  shows: ShowObject[]
}) => {
  const isAuthorized = useAuthorized()

  const showsGroupOne = slice(shows, 0, 6)
  const showsGroupTwo = slice(shows, 6, 12)
  const showsGroupThree = slice(shows, 12, 18)
  const showsGroupRest = slice(shows, 18)

  return (
    <React.Fragment>
      <Grid
        gap={3}
        sx={{
          pb: 3,
          color: 'text.body',
          gridTemplateColumns: verticalList
            ? ['1fr']
            : [
                '1fr',
                'repeat(2, 1fr)',
                'repeat(2, 1fr)',
                'repeat(2, 1fr)',
                'repeat(3, 1fr)',
                'repeat(4, 1fr)',
              ],
        }}
        {...rest}
      >
        {showsGroupOne.map((show) => (
          <Item
            key={`item-${show.id}`}
            show={show}
            verticalList={verticalList}
            hideFavorites={!isAuthorized}
          />
        ))}
        {showBanners && !!showsGroupOne.length && (
          <Box
            sx={{
              gridColumn: [
                'span 1',
                'span 2',
                'span 2',
                'span 2',
                'span 3',
                'span 4',
              ],
            }}
          >
            <Banner
              title="Cinekid online festival"
              subtitle="Ontdek ruim 35 workhops, films en games. Lekker kijken wanneer jij wil! Alleen óf met de hele familie."
              videoUrl="/videos/Cinekid_Play_15sec_square.mp4"
              linkTo="/festival"
              bg="pink"
            />
          </Box>
        )}
        {showsGroupTwo.map((show) => (
          <Item
            key={`item-${show.id}`}
            show={show}
            verticalList={verticalList}
            hideFavorites={!isAuthorized}
          />
        ))}
        {showBanners && !!showsGroupTwo.length && (
          <Box
            sx={{
              gridColumn: [
                'span 1',
                'span 2',
                'span 2',
                'span 2',
                'span 3',
                'span 4',
              ],
            }}
          >
            <Banner
              title="Cinekid Festivalbox"
              subtitle="Beleef het Cinekid Festival bij je thuis! De festivalbox zit bomvol leuke workshops en opdrachten, waarmee je zelf aan de slag kunt."
              imageUrl="/images/banners/festival-box.jpg"
              linkTo="https://www.cinekid.nl/festivalbox"
            />
          </Box>
        )}
        {showsGroupThree.map((show) => (
          <Item
            key={`item-${show.id}`}
            show={show}
            verticalList={verticalList}
            hideFavorites={!isAuthorized}
          />
        ))}
        {showBanners && !!showsGroupThree.length && (
          <Box
            sx={{
              gridColumn: [
                'span 1',
                'span 2',
                'span 2',
                'span 2',
                'span 3',
                'span 4',
              ],
            }}
          >
            <Banner
              title="Ben jij in het MediaLab geweest?"
              subtitle="Bekijk hier je zelfgemaakte MediaLab werkjes"
              linkTo="https://medialab-community.cinekid.nl/"
            />
          </Box>
        )}
        {showsGroupRest.map((show) => (
          <Item
            key={`item-${show.id}`}
            show={show}
            verticalList={verticalList}
            hideFavorites={!isAuthorized}
          />
        ))}
      </Grid>
    </React.Fragment>
  )
}

export default ItemsList
