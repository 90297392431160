/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import * as React from 'react'
import { a, useTransition } from 'react-spring'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import useUIStore from '@stores/ui'
import MenuButton from '@ui/MenuButton'

import { Spinner } from '@components/Spinner'
import { getThemeColor } from '@utils/getThemeColor'
import bgGridImage from '@styles/images/bg-grid.svg'
import bgPlanet from '@styles/images/bg-planet.svg'

const AnimatedDialogOverlay = a(DialogOverlay)
const AnimatedDialogContent = a(DialogContent)

export const Modal = ({
  children,
  name,
  backgroundColor,
  backgroundImage,
  borderColor = 'yellow',
  closeButtonColor,
  narrow = false,
  isLoading = false,
  close = true,
  ...rest
}: {
  children: React.ReactNode
  name: string
  backgroundColor?: string
  borderColor?: string
  closeButtonColor?: string
  backgroundImage?: 'grid' | 'planet'
  narrow?: boolean
  isLoading?: boolean
  close?: boolean
}) => {
  const activeModal = useUIStore((state) => state.modal)
  const toggleModal = useUIStore((state) => state.actions.toggleModal)
  const transition = useTransition(name === activeModal, {
    from: { opacity: 0, scale: 0.9 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 1.2 },
  })

  const handleClose = () => {
    if (!close) return
    toggleModal(undefined)
  }

  closeButtonColor = closeButtonColor ? closeButtonColor : borderColor

  return transition(
    ({ opacity, scale }, item) =>
      item && (
        <AnimatedDialogOverlay
          style={{ opacity }}
          sx={{
            bg: 'rgba(0,0,0,0.5)',
            zIndex: 950,
            display: 'flex',
            flexDirection: 'column',
          }}
          onDismiss={handleClose}
        >
          <Box sx={{ flexGrow: 1 }} />
          <AnimatedDialogContent
            style={{ scale }}
            sx={{
              flexShrink: 0,
              position: 'relative',
              color: 'white',
              bg: backgroundColor ? getThemeColor(backgroundColor) : 'dark',
              backgroundImage:
                backgroundImage &&
                `url(${backgroundImage === 'grid' ? bgGridImage : bgPlanet})`,
              backgroundRepeat:
                backgroundImage === 'grid' ? 'repeat' : 'norepeat',
              backgroundSize: backgroundImage !== 'grid' ? 'cover' : 'unset',
              backgroundPosition:
                backgroundImage === 'grid' ? 'top left' : 'center center',
              border: `2px solid ${getThemeColor(borderColor)}`,
              px: [3, 4, 6],
              pt: [6, 5, 6],
              pb: [5, 4, 5],
              width: ['100%', '95%'],
              maxWidth: narrow ? '730px' : '1016px',
              height: ['100%', 'auto'],
              overflow: 'auto',
              '&[data-reach-dialog-content]': {
                m: [0, '16px auto'],
              },
            }}
            {...rest}
          >
            {isLoading ? <Spinner /> : children}
            {close && (
              <MenuButton
                onClick={handleClose}
                isOpen
                color={closeButtonColor}
                sx={{ position: 'absolute', top: '30px', right: [2, 5] }}
              />
            )}
          </AnimatedDialogContent>
          <Box sx={{ flexGrow: 1 }} />
        </AnimatedDialogOverlay>
      )
  )
}
