import { useState } from 'react'
import useIsomorphicEffect from '@hooks/useIsomorphicEffect'

const useBreakpoint = (query: string) => {
  const [isMatch, setIsMatch] = useState(true)

  useIsomorphicEffect(() => {
    let mounted = true
    const list = window.matchMedia(query)
    const onMatchChange = () => mounted && list && setIsMatch(list.matches)
    if (!list) return
    // This is executed on the client, so the value might've changed
    onMatchChange()
    // Listen for further changes
    list.addListener(onMatchChange)
    // Whenever the breakpoint unmounts stop listening for changes
    return () => {
      mounted = false
      list.removeListener(onMatchChange)
    }
  }, [setIsMatch, query])
  return isMatch
}

export default useBreakpoint
