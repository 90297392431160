/** @jsx jsx */
import { jsx } from 'theme-ui'
import { a, useTransition } from 'react-spring'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import useUIStore from '@stores/ui'
import MenuButton from '@ui/MenuButton'

const AnimatedDialogOverlay = a(DialogOverlay)
const AnimatedDialogContent = a(DialogContent)

export const Panel = ({
  children,
  name,
  color = 'primary',
  side = 'left',
  close = true,
  ...rest
}: {
  children: React.ReactNode
  name: string
  close?: boolean
  color?: string
  side?: 'left' | 'right'
}) => {
  const activeModal = useUIStore((state) => state.modal)
  const toggleModal = useUIStore((state) => state.actions.toggleModal)
  const transition = useTransition(name === activeModal, {
    from: { opacity: 0.5, x: side === 'left' ? `-50%` : `50%` },
    enter: { opacity: 1, x: `0%` },
    leave: { opacity: 0, x: side === 'left' ? `-100%` : `100%` },
  })

  const handleClose = () => {
    toggleModal(undefined)
  }

  return transition(
    ({ opacity, x }, item) =>
      item && (
        <AnimatedDialogOverlay
          style={{ opacity }}
          sx={{
            background: 'rgba(0,0,0,0.25)',
            overflow: 'hidden',
            zIndex: 950,
          }}
          onDismiss={handleClose}
        >
          <AnimatedDialogContent
            style={{ x }}
            sx={{
              position: 'absolute',
              margin: 0,
              left: side === 'left' ? 0 : 'initial',
              right: side === 'left' ? 'initial' : 0,
              height: '100%',
              width: '100%',
              borderLeft: side === 'left' ? 'none' : '2px solid black',
              borderRight: side === 'left' ? '2px solid black' : 'none',
              overflow: 'auto',
            }}
            {...rest}
          >
            {children}
            {close && (
              <MenuButton
                onClick={handleClose}
                isOpen
                sx={{ position: 'absolute', top: '1em', right: '1em' }}
                color={color}
              />
            )}
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  )
}
