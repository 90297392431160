import { useState, useRef, useEffect, useCallback } from 'react'
import { ShowObject, fetchFavorites } from '@api/resources'

import useToken from './useToken'
import useProfileId from './useProfileId'

function useFavorites(fetch = true) {
  const isFresh = useRef(false)
  const isMounted = useRef(true)
  const token = useToken()
  const profileId = useProfileId()

  const [shows, setShows] = useState<ShowObject[]>([])
  const [isLoading, setLoading] = useState(true)

  const refresh = useCallback(() => {
    if (!profileId || !token) return
    if (isMounted.current) setLoading(true)

    fetchFavorites(profileId, { token })
      .then((data) => {
        if (!isMounted.current) return
        setShows(data)
      })
      .finally(() => {
        if (!isMounted.current) return
        setLoading(false)
      })
  }, [profileId, token])

  useEffect(() => {
    if (fetch && !isFresh.current) {
      refresh()
      isFresh.current = true
    }
  }, [fetch, refresh])

  useEffect(() => {
    isFresh.current = false
  }, [profileId])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return { isLoading, shows, refresh }
}

export default useFavorites
