import create from 'zustand'
import { Howler } from 'howler'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'
import cookie from 'js-cookie'

import { getCookieDomain } from '@stores/analytics'

import * as audio from '@components/3d/Audio'
import { PlanetObject } from '@utils/generateCollectionPlanet'
import { CollectionObject } from '@api/resources'
import useUIStore from '@stores/ui'

const SOUND_COOKIE = 'sound'
const SOUND_ON = 'true'
const SOUND_OFF = 'false'

type State = {
  sound: boolean
  isFlying: boolean
  isFastTraveling: boolean
  hoveredPlanet?: PlanetObject
  hoveredJunk?: any
  clicked: boolean
  worldVisible: boolean
  actions: {
    initiate: (activePlanet?: CollectionObject) => void
    toggleSound: (soundOn?: boolean) => void
    setClicked: (clicked: boolean) => void
    setFlying: (flying: boolean) => void
    setHoveredPlanet: (planet: PlanetObject | undefined) => void
    setHoveredJunk: (junk: any | undefined) => void
    setWorldVisible: (visible: boolean) => void
  }
}

const use3DStore = create<State>((set, get) => {
  const hideHelp = useUIStore.getState().hideHelp
  let helpTimer: any
  return {
    sound: cookie.get(SOUND_COOKIE) === SOUND_ON,
    isFlying: false,
    isFastTraveling: false,
    hoveredPlanet: undefined,
    hoveredJunk: undefined,
    clicked: false,
    worldVisible: false,
    actions: {
      initiate(activePlanet = undefined) {
        console.log('3d initiated')
        clearTimeout(helpTimer)
        set({ worldVisible: true })
        if (activePlanet) {
          audio.landing.play()
        } else {
          get().actions.toggleSound(get().sound)
          audio.hud.play()
          if (!hideHelp)
            helpTimer = setTimeout(() => {
              const { actions, modal } = useUIStore.getState()
              if (!modal && get().worldVisible) actions.toggleModal('help')
            }, 15000)
        }
      },
      toggleSound(sound = !get().sound) {
        set({ sound })
        if (sound) {
          Howler.mute(false)
          audio.loop.volume(1)
          audio.loop2.volume(1)
          audio.loop.play()
          audio.loop2.play()
        } else {
          Howler.mute(true)
        }
      },
      setClicked(clicked) {
        set({ clicked })
        if (clicked) clearTimeout(helpTimer)
      },
      setWorldVisible(worldVisible) {
        set({ worldVisible })
      },
      setFlying(flying) {
        const isFlying = get().isFlying

        if (flying && !isFlying) {
          audio.flyingEnd.stop()
          audio.flyingLoop.stop()
          audio.flyingLoop.volume(1)
          audio.flyingLoop.loop(true)
          audio.flyingStart.play()
        } else if (!flying && isFlying) {
          audio.flyingStart.stop()
          audio.flyingLoop.fade(1, 0, 800).once('end', () => {
            audio.flyingLoop.stop()
            audio.flyingLoop.volume(1)
          })
          audio.flyingEnd.play()
        }

        set({ isFlying: flying })
        if (flying) clearTimeout(helpTimer)
      },
      setHoveredPlanet(planet) {
        set({ hoveredPlanet: planet })
        if (planet) audio.hover.play()
      },
      setHoveredJunk(junk) {
        set({ hoveredJunk: junk })
        if (junk) audio.hover.play()
      },
    },
    mutation: {
      lineMaterial: new LineMaterial({
        color: 0x000000,
        linewidth: 2,
        flatShading: true,
        precision: 'lowp',
      }),
    },
  }
})

// Whenever the token changes, write it to the localStorage
use3DStore.subscribe((state: State) => {
  const soundOn = state.sound

  cookie.set(SOUND_COOKIE, soundOn ? SOUND_ON : SOUND_OFF, {
    expires: 365,
    domain: getCookieDomain(location?.host),
    sameSite: 'lax',
  })
})

export default use3DStore
