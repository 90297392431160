/** @jsx jsx */
import { Box, Flex, jsx, Text, Image, Heading, Grid } from 'theme-ui'
import React, { Fragment } from 'react'
import Link from 'next/link'

import useAuthorized from '@api/hooks/useAuthorized'
import useProfile from '@api/hooks/useProfile'
import { Panel } from '@components/Panel'
import { Button, LinkButton } from '@ui/Button'
import { getThemeColor } from '@utils/getThemeColor'

import bgGridImage from '@styles/images/bg-grid-negative.svg'
import logo from '@styles/images/logo.svg'
import useUIStore from '@stores/ui'
import { useViewLimit } from '@hooks/useViewLimit'

const Divider = () => (
  <Box
    sx={{
      borderTop: `2px solid ${getThemeColor('primary')}`,
      height: 0,
      my: 4,
      width: '100%',
    }}
  />
)

const SideLinks = ({ authorized }: { authorized?: boolean }) => {
  const toggleModal = useUIStore((state) => state.actions.toggleModal)

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        'a, button': {
          mx: 0,
          display: 'inline-flex',
          svg: {
            width: '2em',
            height: '2em',
            maxHeight: '1.5em',
            mr: '1em',
          },
        },
      }}
    >
      <Button
        variant="sideMenu"
        label="Alle Planeten"
        icon="planet"
        onClick={() => {
          toggleModal('index')
        }}
      />
      <LinkButton
        icon="suit"
        variant="sideMenu"
        label="Werkjes"
        href="https://medialab-community.cinekid.nl/"
      />
      {authorized && (
        <Fragment>
          <LinkButton
            icon="suit"
            variant="sideMenu"
            label="Ruimtepak"
            href="/ruimtepak"
          />
          <LinkButton
            variant="sideMenu"
            label="live"
            icon="live"
            href="/live"
          />
          <LinkButton
            variant="sideMenu"
            label="overzicht"
            icon="search"
            href="/overzicht"
          />
          <Button
            variant="sideMenu"
            label="Favorieten"
            icon="heart"
            onClick={() => toggleModal('favorites')}
          />
          <Button
            variant="sideMenu"
            label="Trofeeën"
            icon="usb"
            onClick={() => {
              toggleModal('trophies')
            }}
          />
        </Fragment>
      )}
    </Flex>
  )
}

const Footer = () => {
  return (
    <Grid
      sx={{
        mt: 5,
        a: {
          cursor: 'pointer',
          textDecoration: 'none',
        },
      }}
    >
      <Text variant="mono.small">
        <Link href="/ouders" passHref>
          <a sx={{ color: 'white' }}>informatie voor ouders</a>
        </Link>
      </Text>
      <Text variant="mono.small">
        <Link href="/pagina/over-cinekid-play" passHref>
          <a sx={{ color: 'white' }}>over cinekid play</a>
        </Link>
      </Text>
      <Text variant="mono.small">
        <Link href="/faq" passHref>
          <a sx={{ color: 'gray' }}>veelgestelde vragen</a>
        </Link>
      </Text>
      <Text variant="mono.small">
        <Link href="/pagina/klantenservice" passHref>
          <a sx={{ color: 'gray' }}>klantenservice</a>
        </Link>
      </Text>
    </Grid>
  )
}

const LoggedOut = () => {
  const { viewLimitDisabled } = useViewLimit()

  return (
    <Fragment>
      <Box sx={{ mb: 3 }}>
        <Heading as="h2" variant="itemTitle">
          Cinekid Play
        </Heading>
        <Text variant="body.small" sx={{ maxWidth: '90%' }}>
          Dé streamingdienst voor super­nieuwsgierige kids.
        </Text>
      </Box>
      {!viewLimitDisabled && (
        <LinkButton
          variant="red"
          label="Aanmelden"
          sx={{ justifySelf: 'flex-start', display: 'inline-block' }}
          href="/ouders"
        />
      )}
      <Divider />
      {!viewLimitDisabled && (
        <React.Fragment>
          <Grid
            sx={{
              a: {
                cursor: 'pointer',
                textDecoration: 'none',
                borderBottom: '1px solid currentColor',
              },
            }}
          >
            <Text variant="mono">
              <Link href="/inloggen" passHref>
                <a sx={{ color: 'yellow' }}>Inloggen</a>
              </Link>
            </Text>
            <Text variant="mono">
              <Link href="/ouders" passHref>
                <a sx={{ color: 'yellow' }}>Meld&nbsp;je&nbsp;aan</a>
              </Link>
            </Text>
          </Grid>

          <Divider />
        </React.Fragment>
      )}

      <SideLinks />
    </Fragment>
  )
}

const LoggedIn = () => {
  const profile = useProfile()

  return (
    <Fragment>
      <Grid
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          justifyItems: 'center',
        }}
      >
        <Image
          src={logo}
          sx={{
            height: '30px',
          }}
        />
        <Text variant="mono.body">{profile ? profile.name : ' '}</Text>
      </Grid>

      <Divider />

      <Grid
        sx={{
          fontSize: 2,
          a: { textDecoration: 'none', cursor: 'pointer' },
        }}
      >
        <Text variant="mono">
          <Link href="/profielen" passHref>
            <a sx={{ color: 'white', textDecoration: 'none' }}>
              Profiel wisselen
            </a>
          </Link>
        </Text>
        <Text variant="mono">
          <Link href="/instellingen" passHref>
            <a sx={{ color: 'white', textDecoration: 'none' }}>Instellingen</a>
          </Link>
        </Text>
        <Text variant="mono">
          <Link href="/uitloggen" passHref>
            <a sx={{ color: 'gray', textDecoration: 'none' }}>Uitloggen</a>
          </Link>
        </Text>
      </Grid>

      <Divider />

      <SideLinks authorized />
    </Fragment>
  )
}

const Menu = ({ ...rest }) => {
  const isAuthorized = useAuthorized()

  return (
    <Panel
      aria-labelledby="Menu"
      side="right"
      name="menu"
      sx={{
        width: '100%',
        maxWidth: ['100%', '24em'],
        p: 0,
        backgroundImage: `url("${bgGridImage}")`,
        backgroundRepeat: 'repeat',
        backgroundPosition: 'left top',
        borderLeftWidth: [0, '2px'],
        borderColor: 'yellow',
        color: 'white',
      }}
      color="yellow"
      {...rest}
    >
      <Grid
        gap={0}
        sx={{
          gridTemplateRows: 'max-content max-content',
          alignContent: 'space-between',
          minHeight: '100%',
          p: [4, 5],
        }}
      >
        <Box>
          {isAuthorized ? <LoggedIn /> : <LoggedOut />}
          <Divider />
        </Box>
        <Footer />
      </Grid>
    </Panel>
  )
}

export default Menu
