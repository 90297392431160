/** @jsx jsx */
import { useEffect, useMemo, useState, Fragment } from 'react'
import { jsx, Grid, Heading, Text, Flex, Box, AspectImage } from 'theme-ui'
import Link from 'next/link'
import sortBy from 'lodash/sortBy'

import { Modal } from '@components/Modal'
import { CollectionObject, fetchCollections } from '@api/resources'
import { Icon } from '@components/Icons'
import { Spinner } from '@components/Spinner'
import { LinkButton } from '@ui/Button'

export const Planet = ({ collection }: { collection: CollectionObject }) => {
  return (
    <Link href={`/planeet/${collection.id}`} passHref>
      <a sx={{ display: 'block', color: 'white', textDecoration: 'none' }}>
        <Flex
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            px: 3,
            mb: 4,
            '&:hover': {
              cursor: 'pointer',
              'a, button': {
                filter: 'brightness(90%)',
              },
            },
          }}
        >
          {collection.planet.theme && (
            <Box sx={{ width: '100%', maxWidth: ['100px', '160px'] }}>
              <AspectImage
                ratio={1 / 1}
                src={`/images/themes/Thema_${collection.planet.theme}.png`}
              />
            </Box>
          )}

          <LinkButton
            href={`/planeet/${collection.id}`}
            sx={{ my: 3, width: '100%', lineHeight: 1 }}
          >
            {collection.title}
          </LinkButton>

          <Text variant="mono.small" sx={{ mx: '-1em' }}>
            {collection.subtitle}
          </Text>
        </Flex>
      </a>
    </Link>
  )
}

export const PlanetIndex = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [innerCollections, setInnerCollections] = useState<
    CollectionObject[] | null
  >(null)
  useEffect(() => {
    if (!innerCollections) {
      setIsLoading(true)
      fetchCollections().then((data: CollectionObject[]) => {
        setIsLoading(false)
        setInnerCollections(data)
      })
    }
  }, [innerCollections, setInnerCollections])

  const sortedCollections = useMemo(() => {
    return sortBy(innerCollections, 'planet.position')
  }, [innerCollections])

  return (
    <Modal
      aria-labelledby="Alle planeten"
      name="index"
      backgroundColor="navy3d"
      borderColor="blue"
      closeButtonColor="primary"
      backgroundImage="planet"
    >
      <Heading as="h2" variant="mono.body" sx={{ mb: 5, color: 'yellow' }}>
        <Icon
          name="PlanetIcon"
          color="primary"
          sx={{ verticalAlign: 'middle', fontSize: '2em' }}
        />{' '}
        Alle planeten
      </Heading>
      {isLoading ? <Spinner /> : <Planets collections={sortedCollections} />}
    </Modal>
  )
}

const Planets = ({ collections }: { collections: CollectionObject[] }) => {
  return (
    <Fragment>
      {collections && (
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr'],
          }}
        >
          {collections.map((collection: CollectionObject) => (
            <Planet key={collection.id} collection={collection} />
          ))}
        </Grid>
      )}
    </Fragment>
  )
}
