/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'

import Favorites from '@ui/Favorites'
import Menu from '@ui/Menu'
import NotLoggedIn from '@components/NotLoggedIn'
import { Trophies } from '@components/Trophies'
import { NewTrophy } from '@components/NewTrophy'
import HelpModal from '@ui/HelpModal'
import OnlineResourceModal from '@ui/OnlineResourceModal'
import { PlanetIndex } from './PlanetIndex'

export const Modals = () => {
  return (
    <Fragment>
      <Favorites />
      <Menu />
      <NotLoggedIn />
      <Trophies />
      <NewTrophy />
      <HelpModal />
      <OnlineResourceModal />
      <PlanetIndex />
    </Fragment>
  )
}
