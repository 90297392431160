/** @jsx jsx */
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { jsx, ThemeDerivedStyles, ThemeUICSSObject } from 'theme-ui'

const shared = {
  content: `''`,
  display: 'block',
  width: 0,
  height: 0,
  position: 'absolute',
  borderColor: 'transparent',
}

export const Wrapper = styled.div(
  ({ top, left, right, hide, angle, height, bW, bColor }) => ({
    position: 'relative',
    background: hide ? 'transparent' : bColor,
    height: height + bW,
    '&::before': !hide &&
      right && {
        ...shared,
        right: 0,
        transform: `translateX(100%)`,
        borderBottom: top && `${height + bW}px solid transparent`,
        borderTop: !top && `${height + bW}px solid transparent`,
        borderLeft: `${angle}px solid ${bColor}`,
      },
    '&::after': !hide &&
      left && {
        ...shared,
        left: 0,
        transform: `translateX(-100%)`,
        borderRight: `${angle}px solid ${bColor}`,
        borderBottom: top && `${height + bW}px solid transparent`,
        borderTop: !top && `${height + bW}px solid transparent`,
      },
  })
)

export const Inner = styled.div(
  ({ top, left, right, hide, angle, height, bW, bwRatio }) => ({
    pointerEvents: 'all',
    position: 'absolute',
    display: 'flex',
    justifyContent: left
      ? right
        ? 'center'
        : 'flex-end'
      : right
      ? 'left'
      : 'center',
    alignItems: top ? 'flex-start' : 'flex-end',
    background: hide ? 'transparent' : 'black',
    left: left && right ? bW * 0.5 : left ? bW * 0.5 : 'auto',
    width: `calc(100% - ${bW * (left && right ? 1 : 0.5)}px)`,
    height: `calc(100% - ${bW}px)`,
    bottom: !top && 0,
    top: top && 0,
    '&::before': !hide &&
      right && {
        ...shared,
        right: 0,
        transform: `translateX(100%)`,
        borderBottom: top && `${height}px solid transparent`,
        borderTop: !top && `${height}px solid transparent`,
        borderLeft: `${Math.round(angle - bW * bwRatio)}px solid black`,
      },
    '&::after': !hide &&
      left && {
        ...shared,
        left: 0,
        top: top && 0,
        bottom: !top && 0,
        zIndex: 200,
        transform: `translateX(-100%)`,
        borderRight: `${Math.round(angle - bW * bwRatio)}px solid black`,
        borderBottom: top && `${height}px solid transparent`,
        borderTop: !top && `${height}px solid transparent`,
      },
  })
)

export const Side = styled.div(
  ({ bColor, top, left, right, bottom, hide, bW }) => ({
    background: hide ? 'transparent' : 'black',
    borderWidth: 0,
    borderColor: bColor,
    borderStyle: 'solid',
    borderLeftWidth: !hide && left && bW,
    borderTopWidth: !hide && top && bW,
    borderBottomWidth: !hide && bottom && bW,
    borderRightWidth: !hide && right && bW,
  })
)

export const Block = ({
  children,
  style,
  innerStyle,
  ...props
}: {
  children: ReactNode
  style?: ThemeUICSSObject | ThemeDerivedStyles
  innerStyle?: ThemeUICSSObject | ThemeDerivedStyles
}) => (
  <Wrapper {...props} sx={style}>
    <Inner {...props} sx={innerStyle}>
      {children}
    </Inner>
  </Wrapper>
)
