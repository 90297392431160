import useSessionStore, { SessionStore } from '../session'

const selectIsAuthorized = (store: SessionStore) => store.isAuthorized
const selectIsLoading = (store: SessionStore) => store.isLoading
const selectProfile = (store: SessionStore) => store.profile
const selectError = (store: SessionStore) => store.error
const selectLogin = (store: SessionStore) => store.actions.login
const selectLoginWithToken = (store: SessionStore) =>
  store.actions.loginWithToken
const selectLogout = (store: SessionStore) => store.actions.logout
const selectGetProfile = (store: SessionStore) => store.actions.getProfile

function useSession() {
  const isAuthorized = useSessionStore(selectIsAuthorized)
  const isLoading = useSessionStore(selectIsLoading)
  const profile = useSessionStore(selectProfile)
  const error = useSessionStore(selectError)
  const login = useSessionStore(selectLogin)
  const loginWithToken = useSessionStore(selectLoginWithToken)
  const logout = useSessionStore(selectLogout)
  const getProfile = useSessionStore(selectGetProfile)

  return {
    isLoading,
    isAuthorized,
    isError: typeof error !== 'undefined',
    currentProfileId: isAuthorized ? profile : undefined,
    error,
    login,
    logout,
    loginWithToken,
    getProfile,
  }
}

export default useSession
