/** @jsx jsx */
import { jsx, Text, Grid, Flex, Heading, Link as ExternalLink } from 'theme-ui'

import { Modal } from '@components/Modal'
import { LinkButton } from '@ui/Button'
import Link from 'next/link'

const NotLoggedIn = () => {
  return (
    <Modal
      aria-labelledby="Niet ingelogged"
      name="not-logged-in"
      backgroundColor="navy3d"
      borderColor="blue"
      backgroundImage="planet"
      narrow={true}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Grid sx={{ textAlign: 'center', justifyContent: 'center' }}>
        <Heading as="h2" variant="mono.body" sx={{ mb: 5, color: 'yellow' }}>
          Je hebt een account nodig
        </Heading>

        <Text variant="body">
          Ontdek het online Cinekid Festival de héle maand oktober voor € 8,-.
          Heb je al een account? Log dan in.
        </Text>

        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            mb: 6,
            flexWrap: 'wrap',
            a: {
              minWidth: '140px',
            },
          }}
        >
          <LinkButton
            href="/inloggen"
            variant="primary"
            label="Inloggen"
            sx={{ display: 'inline-block', flexGrow: 0, mb: 3, mr: 3 }}
          />
          <LinkButton
            href="/ouders"
            variant="red"
            color="yellow"
            label="Aanmelden"
            sx={{ display: 'inline-block', flexGrow: 0, mb: 3 }}
          />
        </Flex>
      </Grid>
      {/* Footer */}
      <Text
        variant="mono"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: '2px solid white',
          borderTopColor: 'blue',
          color: 'white',
          display: 'flex',
          flexWrap: 'wrap',
          py: 0,
          fontSize: 1,
          a: {
            textAlign: 'center',
            boxSizing: 'border-box',
            display: 'inline-block',
            width: '50%',
            cursor: 'pointer',
            flexGrow: 0,
            flexShrink: 0,
            mx: 0,
            textDecoration: 'none',
            py: 1,
            px: 2,
            color: 'white',
            '&:hover': {
              textDecoration: 'underline',
            },
            '&:first-child': {
              borderRight: '2px solid white',
              borderRightColor: 'blue',
            },
          },
        }}
      >
        <ExternalLink href={process.env.NEXT_PUBLIC_REDEEM_COUPON_URL}>
          Ik heb een kortingscode
        </ExternalLink>
        <Link href="/ouders" passHref>
          <a>Informatie voor ouders</a>
        </Link>
      </Text>
    </Modal>
  )
}

export default NotLoggedIn
