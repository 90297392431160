/** @jsx jsx */
import { jsx, Box, Grid, AspectRatio, Text, Flex } from 'theme-ui'
import Link from 'next/link'

import Heading from '@components/ui/Heading'
import ItemMetadata from '@components/ui/ItemMetadata'
import Badge from '@components/ui/Badge'
import FestivalBadge from '@components/ui/FestivalBadge'
import AddToFavorites from '@components/ui/AddToFavorites'
import { ShowObject } from '@api/resources'
import { getFormatedDateString } from '@utils/date'
import { getThemeColor } from '@utils/getThemeColor'

export const getTypeColor = (
  type: string | undefined,
  defaultColor = 'dark'
) => {
  if (!type) return getThemeColor(defaultColor)

  switch (type.toLowerCase()) {
    case 'masterclass':
      return getThemeColor('green')
    case 'workshop':
    case 'tutorial':
    case 'tutorials':
      return getThemeColor('blue')
    case 'medialab':
      return getThemeColor('red')
    default:
      return getThemeColor(defaultColor)
  }
}

const Labels = ({
  hideBadge = false,
  availabilityType,
  availabilityStartsAt,
  labels,
}: {
  hideBadge: boolean
  availabilityType: string
  availabilityStartsAt?: string
  labels?: string[]
}) => {
  return (
    <Flex
      sx={{
        position: 'absolute',
        zIndex: 2,
        top: 2,
        left: 2,
        flexDirection: 'row',
        flexWrap: 'wrap',
        '> div': { mb: 1, mr: 3, flexShrink: 0 },
      }}
    >
      {!hideBadge && availabilityType === 'live' && availabilityStartsAt && (
        <Badge>
          <Text variant="mono">
            <span style={{ color: 'gray' }}>Live</span>{' '}
            <span>{getFormatedDateString(availabilityStartsAt)}</span>
          </Text>
        </Badge>
      )}

      {labels && labels[0] && (
        <Badge bg="blue" color="primary">
          <Text variant="mono">{labels[0]}</Text>
        </Badge>
      )}
    </Flex>
  )
}

const Item = ({
  show,
  hideBadge = false,
  hideFavorites,
  verticalList,
  ...rest
}: {
  show: ShowObject
  hideBadge?: boolean
  hideFavorites?: boolean
  verticalList?: boolean
}) => {
  const {
    title,
    type,
    description,
    labels,
    availabilityType,
    availabilityStartsAt,
    festivalShow,
    price,
  } = show
  const imageURL = show.image?.versions?.medium?.url

  return (
    <Link href={`/bekijk/${show.id}`} passHref>
      <a sx={{ textDecoration: 'none', width: '100%', color: 'inherit' }}>
        <Grid
          gap={0}
          sx={{
            position: 'relative',
            gridTemplateColumns: [
              '1fr',
              '1fr',
              verticalList ? '1fr 2fr' : '1fr',
            ],
            gridTemplateRows: [
              `'image' 'content'`,
              `'image content'`,
              verticalList ? `'image content'` : `'image' 'content'`,
            ],
            height: '100%',
            overflow: 'hidden',
            borderRadius: 1,
            alignContent: 'flex-start',
            bg: 'white',
            '&:hover': {
              cursor: 'pointer',
              bg: 'lightgray',
            },
          }}
          {...rest}
        >
          <AspectRatio
            ratio={16 / 9}
            sx={{
              width: '100%',
              height: [0, '100%', verticalList ? '100%' : 0],
              pb: ['56.25%', 0, verticalList ? 0 : '56.25%'],
              backgroundImage: `url("${imageURL}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              bg: 'lightgray',
            }}
            aria-labelledby={title}
          >
            <Labels
              {...{ hideBadge, availabilityType, availabilityStartsAt, labels }}
            />
            {festivalShow && (
              <Flex
                sx={{
                  position: 'absolute',
                  top: 20,
                  right: 0,
                  zIndex: 2,
                  flexDirection: 'column',
                }}
              >
                <FestivalBadge>
                  <Heading as="h4" sx={{ fontSize: 4 }}>
                    Festival
                  </Heading>
                </FestivalBadge>
                <FestivalBadge
                  sx={{ mt: 2 }}
                  bg={price == 'paid' ? 'red' : 'yellow'}
                >
                  <Heading as="h5">
                    {price == 'paid' ? 'Betaald' : 'Gratis'}
                  </Heading>
                </FestivalBadge>
              </Flex>
            )}
          </AspectRatio>

          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: verticalList ? 'center' : 'flex-start',
              p: 3,
              flexGrow: 1,
            }}
          >
            {/* Titles, meta, like button */}
            <Grid
              gap={2}
              sx={{
                mb: 2,
                gridTemplateAreas: verticalList
                  ? [
                      `'title favorites' 'description description'`,
                      `'title favorites' 'description favorites'`,
                    ]
                  : [
                      `'title favorites' 'description description'`,
                      `'title favorites' 'description favorites'`,
                      `'title favorites' 'description description'`,
                    ],
                gridTemplateColumns: '1fr max-content',
                gridTemplateRows: 'max-content max-content',
              }}
            >
              <Box>
                <Heading
                  as="h3"
                  sx={{
                    color: getTypeColor(type),
                    overflowWrap: 'break-word',
                  }}
                >
                  {title}
                </Heading>

                <ItemMetadata show={show} showIcons={true} small={true} />
              </Box>

              {hideFavorites ? (
                <Box />
              ) : (
                <Box
                  sx={{
                    alignSelf: verticalList
                      ? ['flex-start', 'center']
                      : ['flex-start', 'center', 'flex-start'],
                    gridArea: 'favorites',
                  }}
                >
                  <AddToFavorites
                    showId={show.id}
                    isSmall={true}
                    likes={show.favoritesCount}
                  />
                </Box>
              )}

              {/* Description */}
              <Text variant="itemDescription">{description}</Text>
            </Grid>
          </Flex>
        </Grid>
      </a>
    </Link>
  )
}

export default Item
