/** @jsx jsx */
import { jsx, Heading as StyledHeading } from 'theme-ui'

const getSize = (element) => {
  switch (element) {
    case 'h1':
      return [28, 40]
    case 'h2':
    default:
      return [26, 32]
    case 'h3':
      return [22, 28]
    case 'h4':
      return 20
    case 'h5':
      return 18
    case 'h6':
      return 12
  }
}

const Heading = (props) => {
  const fontSize = props.as ? getSize(props.as) : getSize('h2')

  return (
    <StyledHeading
      sx={{
        textTransform: 'uppercase',
        fontSize,
        lineHeight: 'heading',
      }}
      {...props}
    />
  )
}

export default Heading
