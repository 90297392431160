import { useState, useRef, useEffect, useCallback } from 'react'
import useSessionStore, { SessionStore } from '../session'
import { selectProfile } from './useProfile'

export const selectLoading = (store: SessionStore) => {
  return store.isLoading
}

export const selectProfileAvatar = (store: SessionStore) => {
  return selectProfile(store)?.avatar
}

export const selectProfileSetAvatar = (store: SessionStore) => {
  return store.actions.setAvatar
}

export const avatarType = {
  one: 0,
  two: 1,
  three: 2,
  four: 3,
  five: 4,
  six: 5,
  seven: 6,
  eight: 7,
  nine: 8,
  ten: 9,
}

export const avatarColors = [
  '#959BAD',
  '#5761EC',
  '#FB2855',
  '#F1D710',
  '#2AEB9E',
  '#F33FA2',
]

const backfill = (colors?: string[]): [string, string, string] => {
  if (!colors || colors.length !== 3) {
    return [avatarColors[0], avatarColors[1], avatarColors[2]]
  }

  return [colors[0], colors[1], colors[2]]
}

export interface AvatarProps {
  isLoading: boolean
  variant: number
  colors: [string, string, string]
  update: (variant: number, colors: [string, string, string]) => void
}

function useAvatar(): AvatarProps {
  const isMounted = useRef(true)
  const isSessionLoading = useSessionStore(selectLoading)
  const [isLoading, setIsLoading] = useState(false)

  const avatar = useSessionStore(selectProfileAvatar)
  const save = useSessionStore(selectProfileSetAvatar)
  const update = useCallback(
    (variant: number, colors: [string, string, string]) => {
      const type = Object.keys(avatarType).find((key: string) => {
        return avatarType[key as keyof typeof avatarType] === variant
      })

      if (!type || colors.length !== 3) return

      setIsLoading(true)
      save(type, colors).finally(() => {
        if (!isMounted.current) return
        setIsLoading(false)
      })
    },
    [save]
  )

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  if (!avatar) {
    return {
      isLoading: isSessionLoading,
      update,
      variant: 1,
      colors: ['#959BAD', '#5761EC', '#FB2855'],
    }
  } else {
    const type = avatar.type || ''
    const valid = Object.prototype.hasOwnProperty.call(avatarType, type)
    const variant = valid ? avatarType[type as keyof typeof avatarType] : 1

    return {
      update,
      isLoading: isSessionLoading || isLoading,
      variant: variant,
      colors: backfill(avatar.colors),
    }
  }
}

export default useAvatar
