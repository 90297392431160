/** @jsx jsx */
import { jsx, Text, Grid, Heading } from 'theme-ui'
import { ellipsis } from 'polished'
import { useEffect } from 'react'
import * as audio from '@components/3d/Audio'

import { Modal } from '@components/Modal'
import { formatISO } from '@utils/date'
import useProfile from '@api/hooks/useProfile'
import useSession from '@api/hooks/useSession'
import useUIStore from '@stores/ui'

import { getTrophyName } from '@components/Trophies'

const Junk = ({ item }) => {
  const name = item.type.replace('COLLECT_JUNK_', '')

  useEffect(() => {
    audio.spacejunk.play()
  }, [])

  return (
    <Grid
      sx={{
        justifyContent: 'center',
        alignContent: 'stretch',
        alignItems: 'stretch',
        rowGap: 2,
      }}
    >
      <img
        src={`/images/junk/${name}.png`}
        sx={{ display: 'block', width: '12em', maxWidth: '100%', mb: 3 }}
      />
      <Text variant="mono.body" sx={{ textAlign: 'center', ...ellipsis() }}>
        {name.replace('_', ' ')}
      </Text>
      <Text
        variant="mono"
        sx={{ textAlign: 'center', fontSize: 0, ...ellipsis() }}
      >
        {formatISO(item.collectedAt, undefined)}
      </Text>
    </Grid>
  )
}

const Trophy = ({ item }) => {
  const name = getTrophyName(item.type)

  useEffect(() => {
    audio.trophy.play()
  }, [])

  return (
    <Grid
      sx={{
        justifyContent: 'center',
        alignContent: 'stretch',
        alignItems: 'stretch',
        rowGap: 2,
      }}
    >
      <img
        src={`/images/trophies/${item.type}.png`}
        sx={{ display: 'block', width: '12em', maxWidth: '100%', mb: 3 }}
      />
      <Text variant="mono.body" sx={{ textAlign: 'center', ...ellipsis() }}>
        {name}
      </Text>
      <Text
        variant="mono"
        sx={{ textAlign: 'center', fontSize: 0, ...ellipsis() }}
      >
        {formatISO(item.collectedAt, undefined)}
      </Text>
    </Grid>
  )
}

export const NewTrophy = () => {
  const profile = useProfile()
  const item = useUIStore((state) => state.hasNewTrophy)
  const setNewTrophy = useUIStore((state) => state.actions.setNewTrophy)

  const { isLoading } = useSession()

  useEffect(() => {
    return () => {
      setNewTrophy(false)
    }
  }, [setNewTrophy])

  if (!profile || !item) return null

  if (item.type === 'COLLECT_THREE_JUNKS') return null // no image

  const isJunk =
    item.type !== 'COLLECT_ONE_JUNK' && item.type.includes('_JUNK_')

  return (
    <Modal
      aria-labelledby="Nieuwe trofee!"
      name="newTrophy"
      backgroundColor="navy3d"
      borderColor="blue"
      backgroundImage="planet"
      isLoading={isLoading}
      narrow
    >
      <Grid>
        <Heading
          as="h2"
          variant="mono.body"
          sx={{ textAlign: 'center', color: 'yellow' }}
        >
          Nieuwe trofee gevonden!
        </Heading>

        <Grid
          sx={{
            flexWrap: 'wrap',
            gridTemplateColumns: ['1fr'],
            gridTemplateRows: 'auto',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            justifyItems: 'stretch',
          }}
        >
          {isJunk ? <Junk item={item} /> : <Trophy item={item} />}
        </Grid>
      </Grid>
    </Modal>
  )
}
