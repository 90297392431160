/** @jsx jsx */
import { jsx, Text, Grid, Flex, Heading } from 'theme-ui'
import { useCallback } from 'react'

import { Modal } from '@components/Modal'
import { Button } from '@ui/Button'
import useUIStore from '@stores/ui'
import useBreakpoint from '@hooks/useBreakpoint'
import { Icon } from '@components/Icons'

const HelpModal = () => {
  const isTouch = useBreakpoint('only screen and (hover: none)')
  const toggleModal = useUIStore((state) => state.actions.toggleModal)
  const setHideHelp = useUIStore((state) => state.actions.setHideHelp)
  const hideHelp = useUIStore((state) => state.hideHelp)

  const handleClose = useCallback(() => {
    toggleModal(undefined)
  }, [toggleModal])

  const handleHide = useCallback(() => {
    setHideHelp(true)
    toggleModal(undefined)
  }, [toggleModal, setHideHelp])

  return (
    <Modal
      aria-labelledby="Help"
      name="help"
      backgroundColor="navy3d"
      borderColor="blue"
      backgroundImage="planet"
      narrow={true}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Grid
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <Heading as="h2" variant="mono.body" sx={{ mb: 5, color: 'yellow' }}>
          Help door het universum
        </Heading>

        <Text variant="body">Druk op 'spatie' om een hyperjump te maken.</Text>

        <Text variant="body">
          Klik op een planeet en ontdek alle films, games en workshops.
        </Text>

        <Grid sx={{ gridTemplateColumns: ['1fr', '1fr 1fr'], mt: 3, mb: 3 }}>
          <Grid
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              borderRight: ['none', '1px solid yellow'],
              borderBottom: ['1px solid yellow', 'none'],
              px: [0, 3],
              py: [3, 0],
            }}
          >
            <Icon
              name={isTouch ? 'SwipeIcon' : 'MouseIcon'}
              size="3em"
              color="yellow"
            />
            <Text variant="small">
              {isTouch
                ? `Sleep je vinger over het scherm om rond te kijken.`
                : `Klik en sleep met je muis om rond te kijken.`}
            </Text>
          </Grid>
          <Grid
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              px: 3,
            }}
          >
            <Icon
              name={isTouch ? 'PinchIcon' : 'KeysIcon'}
              size="3em"
              color="yellow"
            />
            <Text variant="small">
              {isTouch
                ? `Knijp met je vingers (pinch) om rond te vliegen van planeet naar planeet.`
                : `Gebruik de pijltjestoetsen om rond te vliegen van planeet naar planeet.`}
            </Text>
          </Grid>
        </Grid>

        {!isTouch && (
          <Text variant="body">
            Druk op 'spatie' om een hyperjump te maken.
          </Text>
        )}
        <Text variant="body">Klik op een planeet om deze te bezoeken.</Text>

        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            mt: 3,
          }}
        >
          {!hideHelp && (
            <Button
              variant="transparent"
              label="Niet meer tonen"
              sx={{ display: 'inline-block' }}
              onClick={handleHide}
            />
          )}
          <Button
            variant="secondary"
            label="Verder vliegen"
            sx={{ display: 'inline-block' }}
            onClick={handleClose}
          />
        </Flex>
      </Grid>
    </Modal>
  )
}

export default HelpModal
