/** @jsx jsx */
import { jsx, Spinner as ThemedSpinner, Grid, Text } from 'theme-ui'

export const Spinner = (props) => {
  return (
    <Grid
      sx={{
        alignItems: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ThemedSpinner {...props} />
      <Text variant="small" color="white">
        Bezig met laden…
      </Text>
    </Grid>
  )
}
